.layout {
  min-height: 100vh;
  grid-template-rows: auto 1fr auto;
}

.topbar {
  position: fixed;
  top: 0;
  z-index: var(--zIndexTopbar);

  width: 100%;
}

.main {
  display: grid;
}

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Topbar transition style */
.topbarMobile,
.topbarDesktop,
.topbarDesktop > div > div > div > div {
  transition: var(--transitionStyle);
}

/* Override background */
.topbarTop {
  background-color: transparent !important;
  box-shadow: var(--boxShadowCustomTopbar) !important;
}

.topbarTop svg {
  fill: var(--colorWhite);
}

/* Override links colors */
.topbarTop > div:first-of-type > div button,
.topbarTop > div:first-of-type a,
.topbarTop > a,
.topbarTop > div:last-of-type > a,
.topbarTop > div:last-of-type > div > button {
  color: var(--colorWhite) !important;

  border-bottom-color: var(--marketplaceColorSecondary) !important;
}

/* Override CustomLinks menu */
.topbarTop > div > div > div > div {
  box-shadow: unset;

  border-top-color: transparent;
  background-color: transparent;

  & li > a > span {
    background-color: var(--marketplaceColorSecondary) !important;
  }
}

.topbarTop > a:first-of-type > div > img {
  filter: invert(1);
}

.topbarScrolling {
  background-color: var(--colorWhite);
}
